import React from 'react';
import './Sobre.css';

function Sobre() {
  return (
    <div className='sobre-container'>
        Sobre
    </div>
  );
}

export default Sobre;
