// Menu.js
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './Menu.css';
import Close from '../../assets/imgs/close.svg';
import Insta from '../../assets/imgs/insta1.svg';
import Wpp from '../../assets/imgs/wpp1.svg';
import menuItems from "./Btns-Content";
import MenuBtn from './MenuBtn';


const Menu = ({ toggleSidebar, isOpen, setIsOpen, scrollToComponent }) => {
  const sidebarRef = useRef(null);

  const closeSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const action = (address) => {
    toggleSidebar();
    if(address !== "Link"){
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeSidebar);
    return () => {
      document.removeEventListener("mousedown", closeSidebar);
    };
  });
  const main = process.env.REACT_APP_MAIN;

  return (
    <>
      <CSSTransition in={isOpen} timeout={500} classNames='fade' unmountOnExit>
        <div>
          <div className='sidebar' ref={sidebarRef}>
            <button
              className='menu-toggle close-menu-btn'
              onClick={toggleSidebar}
            >
              <img className='close-img' src={Close} alt='Close' />
            </button>

            <div className="menu-btns">
              {menuItems.map((menuItem, index) => (
              <div key={index}>
                <MenuBtn data={menuItem} action={action} />
              </div>
              ))}
            </div>

            <div className='redes'>
            <a href="https://wa.me/5584998794117" target="_blank" rel="leticiawpp">
            <img className='wpp' src={Wpp} alt='wpp' />
            </a>
            <a href="https://www.instagram.com/letmartins_/" target="_blank" rel="leticiainsta">
            <img className='insta' src={Insta} alt='insta' /></a>
            </div>
          </div>
          <div className='curtain'></div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Menu;

