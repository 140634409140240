const type = "Link";

const menuItems = [
    
    {
        label: "Trabalhos",
        type: type,
        to: "fotos",

    },
    /* {
        label: "Sobre",
        type: type,
        to: "sobre",
    }, */
    {
        label: "Contato",
        type: type,
        to: "contato",
    }
];

export default menuItems;
