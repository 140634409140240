import React, { useState, useEffect } from 'react';
import './Home.css';
import Imagens from './home.json';
import { CSSTransition } from 'react-transition-group';
import {Link } from 'react-router-dom';

function Home() {
    const [overlay, setOverlay] = useState(true);

    const toggleOverlay = () => {
      setOverlay(false);
    };

  const main = process.env.REACT_APP_MAIN;
return (
    <>
    <CSSTransition in={overlay} timeout={1000} classNames='fade' unmountOnExit>

    <Link to={main + 'fotos'} className="overlay-inicial">
    <div className="logo">
        <button alt="home">
          <h1 className="logo-name" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            LETICIA MARTINS
          </h1>
        </button>
      </div>
</Link>

    </CSSTransition>
    </>
);
}

// Funções para o efeito de espaçamento de letras
function handleMouseEnter(e) {
    e.target.classList.add("letter-spaced");
  }
  
  function handleMouseLeave(e) {
    e.target.classList.remove("letter-spaced");
  }

export default Home;
