import React, { useState, useEffect } from 'react';
import './Trabalho.css';
import { useParams, Link } from 'react-router-dom';

function Trabalho() {
  const { nomeTrabalho } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const main = process.env.REACT_APP_MAIN;
  useEffect(() => {
    fetch(`${main}${nomeTrabalho}/${nomeTrabalho}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Criar um array de promises para carregar todas as imagens
        const imagePromises = data.map(imagem => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = reject;
            img.src = require(`../../assets/imgs/otimizadas/${nomeTrabalho}/${imagem.title}`);
          });
        });
  
        // Aguardar todas as promessas de carregamento das imagens
        Promise.all(imagePromises)
          .then(() => {
            setData(data);
            setLoading(false); // Definir loading como falso após o carregamento de todas as imagens
          })
          .catch(error => {
            console.error('There has been a problem with loading images:', error);
            setLoading(false); // Definir loading como falso em caso de erro também
          });
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        setLoading(false); // Definir loading como falso em caso de erro também
      });
  }, [main, nomeTrabalho]);
  


  return (
    <div className="Trabalho-container">
<p className='bread-crumble'> <Link className="trabalhosgray" to={main}>Trabalhos</Link> {'>'} {nomeTrabalho}</p>

{loading ? ( // Verificar se o carregamento está em andamento
<div className="loader-overlay">
        
        <span class="loader"></span>
      </div>
      ) : (

      <div className='trabalho-grid'>
      {data && data.map((imagem) => (
          <img
            key={imagem.id}
            src={require(`../../assets/imgs/otimizadas/${nomeTrabalho}/${imagem.title}`)}
            alt={imagem.title}
          />
        ))}

      </div>
          )}
    </div>
  );
}

export default Trabalho;
