import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import Menu from "./Menu";
import "./Header.css";
import menuItems from "./Btns-Content";
import MenuBtn from "./MenuBtn";

const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (
      <div key={index}>
        <MenuBtn action={action} data={menuItem} scrollToComponent={scrollToComponent} />
      </div>
    ));
  }, [menuItems]);

  const main = process.env.REACT_APP_MAIN;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="logo">
          <Link to={main + 'fotos'} alt="home">
            <h1 className="logo-name" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              LETICIA MARTINS
            </h1>
          </Link>
        </div>

        <button className="menu-toggle" onClick={toggleSidebar}>
          <img src={MenuBars} alt="Menu Bars" />
        </button>
      </div>
      <Menu toggleSidebar={toggleSidebar} isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

// Funções para o efeito de espaçamento de letras
function handleMouseEnter(e) {
  e.target.classList.add("letter-spaced");
}

function handleMouseLeave(e) {
  e.target.classList.remove("letter-spaced");
}

export default Header;
