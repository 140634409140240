import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/shared/Header';
import Fotos from './components/fotos/Fotos';
import Sobre from './components/sobre/Sobre';
import Contato from './components/contato/Contato';
import './App.css';
import './assets/styles/Reset.css';
import Trabalho from './components/Trabalho/Trabalho';
import Home from './components/home/Home';

function App() {
  const main = process.env.REACT_APP_MAIN;
  return (
    <Router>
      <div className='App'>
        
          <Header />
            <div className='body-wrapper'>
              <Routes>

              <Route path={main} element={<Home/>} />
              
                <Route path={main + 'fotos'} element={<Fotos />} />
                <Route
                    path={main + "trabalhos/:nomeTrabalho"}
                    element={<Trabalho />}
                  />
                <Route path={main +  'sobre'} element={<Sobre />} />
                <Route path={main + 'contato'} element={<Contato />} />
              </Routes>
            </div>
        </div>
    </Router>
  );
}

export default App;

