
import "./Fotos.css";
import React, { useState, useEffect } from "react";
import SauloRchh from "../../assets/imgs/otimizadas/SAULORCH/sr9.webp";
import YSA from "../../assets/imgs/otimizadas/YSA/ysa2.webp";
import MASOARTWEARFASE4 from "../../assets/imgs/otimizadas/MASOARTWEARFASE4/ma4-1.webp";
import MASOARTWEARFASE5 from "../../assets/imgs/otimizadas/MASOARTWEARFASE5/ma5-00.webp";
import BEHOT from "../../assets/imgs/otimizadas/BEHOT/behot6.webp";
import POPS from "../../assets/imgs/otimizadas/POPS/p6.webp";
import MARIALUAACESSORIOS from "../../assets/imgs/otimizadas/MARIALUAACESSORIOS/mla2.webp";
import SAULORCHANACLAUDIA from "../../assets/imgs/otimizadas/SAULORCHANACLAUDIA/sr-ac6.webp"; //
import  PULSELAB from "../../assets/imgs/otimizadas/PULSELAB/lc5.webp";
import {Link } from 'react-router-dom';





function Fotos() {



  const main = process.env.REACT_APP_MAIN;

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const images = [SauloRchh, YSA, MASOARTWEARFASE4, MASOARTWEARFASE5, BEHOT, POPS, MARIALUAACESSORIOS, SAULORCHANACLAUDIA, PULSELAB];
    let loadedCount = 0;

    const loadImage = src => {
      const image = new Image();
      image.onload = () => {
        loadedCount++;
        if (loadedCount === images.length) {
          setImagesLoaded(true);
        }
      };
      image.src = src;
    };

    images.forEach(imageSrc => {
      loadImage(imageSrc);
    });
  }, []);

  
  return (
    <>
{imagesLoaded ? (

      <div className="fotos-container">
      
        <Link to={main + "trabalhos/SAULORCH"}>
          <div className="galeria">
            <img src={SauloRchh} alt="SauloRch" />
            <div className="image-title">SAULORCH</div>
          </div>
        </Link>
          <Link to={main + "trabalhos/YSA"}>
            <div className="galeria">
              <img src={YSA} alt="YSA" />
              <div className="image-title">YSA</div>
            </div>
          </Link>
          <Link to={main + "trabalhos/MASOARTWEARFASE4"}>
            <div className="galeria">
              <img src={MASOARTWEARFASE4} alt="MASOARTWEARFASE4" />
              <div className="image-title">MASO ARTWEAR FASE 4</div>
            </div>
          </Link>
          <Link to={main + "trabalhos/MASOARTWEARFASE5"}>
            <div className="galeria">
              <img src={MASOARTWEARFASE5} alt="MASOARTWEARFASE5" />
              <div className="image-title">MASO ARTWEAR FASE 5</div>
            </div>
          </Link>
          <Link to={main + "trabalhos/BEHOT"}>
            <div className="galeria">
              <img src={BEHOT} alt="BEHOT" />
              <div className="image-title">BEHOT</div>
            </div>
          </Link>
          <Link to={main + "trabalhos/POPS"}>
            <div className="galeria">
              <img src={POPS} alt="POPS" />
              <div className="image-title">POPS</div>
            <div className="overlay"></div>
            </div>
          </Link>
          <Link to={main + "trabalhos/PULSELAB"}>
            <div className="galeria">
              <img src={PULSELAB} alt="LABCOOL" />
              <div className="image-title">PULSE LAB</div>
            </div>
          </Link> 
          <Link to={main + "trabalhos/MARIALUAACESSORIOS"}>
            <div className="galeria">
              <img src={MARIALUAACESSORIOS} alt="MARIALUA" />
              <div className="image-title">MARIA LUA ACESSÓRIOS</div>
            </div>
          </Link>
        
         
           <Link to={main + "trabalhos/SAULORCHANACLAUDIA"}>
            <div className="galeria">
              <img src={SAULORCHANACLAUDIA} alt="SAULORCHANACLAUDIA" />
              <div className="image-title">SAULORCH + ANA CLAUDIA</div>
            </div>
          </Link>
      
      </div>
      ) : (<div className="loader-overlay">
        
        <span class="loader"></span>
      </div>
      )}
    </>
  );
}




export default Fotos;
