import React from "react";
import "./Contato.css";
import Insta from "../../assets/imgs/instabranco.svg";
import Wpp from "../../assets/imgs/wppbranco.svg";
import le from "../../assets/imgs/print88.jpg";
import { Link } from "react-router-dom";
function Contato() {
  const main = process.env.REACT_APP_MAIN;
  return (
    <div className="contato-container">
      <div className="identificar">
        <p className="bread-crumble">
          {" "}
          <Link className="trabalhosgray" to={main}>
            Trabalhos
          </Link>{" "}
          {"> Contato"}{" "}
        </p>
        <div className="contato-img1"></div>
        <img src={le} alt="MA4" />
      </div>

      <div className="contatinhos">
       

        <div className="redes">
          <div className="clicaveis">
            <img src={Insta} alt="instagram" />
            <a className="mouse" onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              href="https://www.instagram.com/letmartins_/"
              target="_blank"
              rel="noreferrer"
            > @letmartins_
              
            </a>
          </div>
          <div className="clicaveis">
            <img src={Wpp} alt="wpp" />
            <a className="mouse"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
              href="https://wa.me/5584998794117"
              target="_blank"
              rel="noreferrer"
            >(84)99879-4117
              
            </a>
          </div>
         
        </div> 
        <div className="titles">
          <a 
            href="mailto:contatoleticiamartinss@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="contato-title"
            >
              contatoleticiamartinss@gmail.com
            </h1>
          </a>
        </div>
      </div>

      {/* <div className='contato-img2'>
      <img src={ctt2} alt="MA" />
      </div> */}
    </div>
  );
}
function handleMouseEnter(e) {
  e.target.classList.add("letter-spaced");
}

function handleMouseLeave(e) {
  e.target.classList.remove("letter-spaced");
}

export default Contato;
